import { useEndSessionMutation, useGetCurrentUserQuery } from '@/auth/api';
import Close from '@/components/Icons/Close';
import Menu from '@/components/Icons/Menu';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import styles from '@/styles/Navigation.module.css';

const UserMenuItems = [
  {
    id: 1,
    title: 'Articles',
    href: '/posts',
  },
  {
    id: 2,
    title: '2FA Settings',
    href: '/mfa/settings',
  },
  {
    id: 3,
    title: 'Sessions',
    href: '/session/list',
  },
];

function Navigation() {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const { data: user, isLoading } = useGetCurrentUserQuery();

  const [signOut] = useEndSessionMutation();

  const onSignoutClicked = async () => {
    try {
      // Get a new session
      signOut().unwrap();
    } catch {
      toast.error('Something went wrong. Please try again');
    }
  };

  const handleMenuClick = () => {
    setIsNavOpen(prev => !prev);
  };

  const handleCloseClick = () => {
    setIsNavOpen(false);
  };

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isNavOpen]);

  return (
    <nav className="flex flex-grow">
      <section className="MOBILE-MENU flex flex-grow justify-end lg:hidden">
        <div
          className="space-y-2 cursor-pointer"
          onClick={() => handleMenuClick()}
        >
          <Menu className="w-[46px] h-[46px]" />
        </div>
        <div
          className={
            isNavOpen
              ? `flex fixed w-full h-screen top-0 left-0 bg-gray-1 z-10 flex-col items-start justify-evenly`
              : `hidden`
          }
        >
          <div className="absolute w-full flex items-center justify-between h-28 md:h-28 top-0 px-4 md:px-10 py-4">
            <div className="flex-shrink-0 mr-4">
              {/* Logo */}
              <Link href="/" className="flex items-center gap-2">
                <Image
                  width={64}
                  height={64}
                  alt="Logo"
                  src="/punch.png"
                  className="invert"
                />
                <h2 className="text-white">PUNCH</h2>
              </Link>
            </div>
            <div className="cursor-pointer" onClick={() => handleCloseClick()}>
              <Close className="w-[46px] h-[46px]" />
            </div>
          </div>
          <div className="w-full p-5">
            {user ? (
              <ul className={styles.nav}>
                {UserMenuItems.map(item => (
                  <li
                    key={item.id}
                    className="border-b-2 text-white border-gray-400 my-8"
                  >
                    <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic py-5">
                      <Link className="no-underline" href={item.href}>
                        <span className="hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                          {item.title}
                        </span>
                      </Link>
                    </span>
                  </li>
                ))}
                <li className="border-b-2 text-white border-gray-400 my-8">
                  <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic py-5">
                    <button
                      className="no-underline appearance-none"
                      onClick={onSignoutClicked}
                    >
                      <span className="hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                        Sign out
                      </span>
                    </button>
                  </span>
                </li>
              </ul>
            ) : (
              <ul className={styles.nav}>
                <li className="border-b-2 text-white border-gray-400 my-8">
                  <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic py-5">
                    <Link className="no-underline" href={`/session`}>
                      <span className="hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                        Sign in / Sign up
                      </span>
                    </Link>
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </section>
      <div className="DESKTOP-MENU justify-end items-center mt-3 text-sm text-white hidden space-x-8 lg:flex flex-grow">
        {user ? (
          <ul className={styles.nav}>
            {UserMenuItems.map(item => (
              <li key={item.id} className="inline-block pr-5 pb-4">
                <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                  <Link className="no-underline" href={item.href}>
                    {item.title}
                  </Link>
                </span>
              </li>
            ))}
            <li className="inline-block pr-5 pb-4">
              <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                <button
                  className="no-underline appearance-none"
                  onClick={onSignoutClicked}
                >
                  Sign out
                </button>
              </span>
            </li>
          </ul>
        ) : (
          <ul className={styles.nav}>
            <li className="inline-block pr-5 pb-4">
              <span className="relative block cursor-pointer hover:font-sans-italic focus:font-sans-italic active:font-sans-italic">
                <Link className="no-underline" href={`/session`}>
                  Sign in / Sign up
                </Link>
              </span>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navigation;
