import classNames from '@/helpers/classNames';

export default function Wrapper(props: {
  children: React.ReactNode;
  fullWidth?: boolean;
}): JSX.Element {
  const { children, fullWidth = false } = props;

  return (
    <div
      className={classNames(
        'relative h-full mx-auto box-content px-4 md:px-10 lg:px-12',
        fullWidth ? '3xl:px-20' : ' 3xl:px-0 max-w-8xl',
      )}
    >
      {children}
    </div>
  );
}
