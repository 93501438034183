import Head from 'next/head';
import { Header } from '@/components/Header';

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-gray-1">
      <Head>
        <title>Punch</title>
        <meta name="description" content="Punch web app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Header />
    </div>
  );
}
