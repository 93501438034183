import { IIcon } from './types';

function Menu({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 24 24">
      <line x1="4" y1="6.5" x2="20" y2="6.5" stroke="white" />
      <line x1="4" y1="11.5" x2="20" y2="11.5" stroke="white" />
      <line x1="4" y1="16.5" x2="20" y2="16.5" stroke="white" />
    </svg>
  );
}

Menu.displayName = 'Menu';

export default Menu;
