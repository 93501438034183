import { IIcon } from './types';

function Close({ className = '' }: IIcon) {
  return (
    <svg className={className} fill="none" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37414 6.29716L6.02058 5.9436L5.31348 6.65071L5.66703 7.00426L10.8474 12.1846L5.66703 17.3649L5.31348 17.7185L6.02058 18.4256L6.37414 18.072L11.5545 12.8917L16.7348 18.072L17.0883 18.4256L17.7954 17.7185L17.4419 17.3649L12.2616 12.1846L17.4419 7.00426L17.7954 6.65071L17.0883 5.9436L16.7348 6.29716L11.5545 11.4775L6.37414 6.29716Z"
        fill="white"
      />
    </svg>
  );
}

Close.displayName = 'Close';

export default Close;
