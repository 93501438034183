import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Wrapper from '@/containers/Wrapper';
import Navigation from '@/session/components/Navigation';
import { COMPONENT_NAME } from './types';

const Header = () => {
  return (
    <header className="w-full bg-gray-1">
      <Wrapper fullWidth>
        <div className="flex items-center justify-between h-28 md:h-28">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/" className="flex items-center gap-2">
              <Image
                width={64}
                height={64}
                alt="Logo"
                src="/punch.png"
                className="invert"
              />
              <h2 className="text-white">PUNCH</h2>
            </Link>
          </div>

          {/* Site navigation */}
          <Navigation />
        </div>
      </Wrapper>
    </header>
  );
};

Header.displayName = COMPONENT_NAME;

export default Header;
