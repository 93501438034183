import {
  CreateUserParams,
  CurrentUser,
  Enable2FAParams,
  MFASettings,
  Session,
  SessionInfo,
} from './types';
import api from 'lib/api/api';
import { DataResponse } from 'lib/api/types';

export interface ThirdPartyAuthData {
  session: {
    state: string;
  };
  url: string;
}

const currentUserApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    createUser: builder.mutation<
      { user: CurrentUser } & Session,
      CreateUserParams
    >({
      query: userData => ({
        url: `/api/users`,
        method: 'post',
        body: userData,
      }),
      transformResponse: r => r as { user: CurrentUser } & Session,
      invalidatesTags: ['CurrentUser'],
    }),

    createSession: builder.mutation<
      Session,
      {
        email: string;
        password?: string;
        type?: string;
        totp_code?: string;
        totp_token?: string;
        device_id?: string | null;
        app_info?: { device?: string; client?: string };
      }
    >({
      query: params => ({
        url: '/api/session',
        method: 'post',
        body: params,
      }),
      invalidatesTags: ['CurrentUser'],
      transformResponse: r => r as Session,
    }),

    refreshSession: builder.mutation<
      Session,
      {
        device_id?: string | null;
        app_info?: { device?: string; client?: string };
      } | void
    >({
      query: params => ({
        url: '/api/session/renew',
        method: 'put',
        body: params,
      }),
      invalidatesTags: ['CurrentUser'],
      transformResponse: r => r as Session,
    }),

    endSession: builder.mutation<void, void>({
      query: () => ({
        url: '/api/session',
        method: 'delete',
      }),
      invalidatesTags: ['CurrentUser'],
    }),

    endSessionById: builder.mutation<void, string>({
      query: sessionId => ({
        url: `/api/session/${sessionId}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, id) => [
        'CurrentUser',
        { type: 'SessionInfo', id },
      ],
    }),

    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => ({
        url: '/api/current_user',
        method: 'get',
      }),
      providesTags: ['CurrentUser'],
      transformResponse: r => r as CurrentUser,
    }),

    getAllSessionsForUser: builder.query<SessionInfo[], void>({
      query: () => ({
        url: '/api/session',
        method: 'get',
      }),
      providesTags: (result = [], error, arg) =>
        result.map(({ id }) => ({ type: 'SessionInfo', id })),
      transformResponse: r => (r as DataResponse<SessionInfo[]>).data,
    }),

    validateEmail: builder.mutation<{ ok: string }, { email: string }>({
      query: ({ email }) => {
        const searchParams = new URLSearchParams();
        searchParams.set('email', email);
        return {
          url: `/api/users?${searchParams.toString()}`,
          method: 'get',
        };
      },
    }),

    thirdPartyAuthorize: builder.mutation<
      ThirdPartyAuthData,
      { provider: string; redirect_uri: string }
    >({
      query: ({ provider, redirect_uri }) => ({
        url: `/api/auth/${provider}/authorize`,
        method: 'POST',
        body: {
          redirect_uri,
        },
      }),
    }),

    getMFASettings: builder.query<MFASettings, void>({
      query: () => ({
        url: '/api/totp',
        method: 'get',
      }),
      providesTags: ['MFASettings'],
      transformResponse: r => (r as DataResponse<MFASettings>).data,
    }),

    enable2FA: builder.mutation<any, Enable2FAParams>({
      query: params => ({
        url: `/api/totp`,
        method: 'post',
        body: params,
      }),
      transformResponse: r => (r as DataResponse<MFASettings>).data,
      invalidatesTags: ['MFASettings'],
    }),
  }),
});

export default currentUserApi;

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useCreateSessionMutation,
  useRefreshSessionMutation,
  useEndSessionMutation,
  useEndSessionByIdMutation,
  useValidateEmailMutation,
  useCreateUserMutation,
  useThirdPartyAuthorizeMutation,
  useGetAllSessionsForUserQuery,
  useGetMFASettingsQuery,
  useEnable2FAMutation,
  util: { getRunningQueriesThunk },
} = currentUserApi;

export const { getCurrentUser } = currentUserApi.endpoints;
